<script setup lang="ts">
import { boolean, object, string, type InferType } from 'yup'
import type { FormSubmitEvent } from '#ui/types'
const errorMessage = useErrorMessage() //传递报错消息
const BGPlay = useBGPlay()
const { $hyc } = useNuxtApp()
const loginf = reactive({
  username: '',
  password: '',
  remember: true,
})
const schema = object({
  username: string().required('Required'),
  password: string()
    .min(8, 'Must be at least 8 characters')
    .required('Required'),
})

type Schema = InferType<typeof schema>

const twofa = ref('')

const loading = ref(false)
const form = useState('loginForm')

const toSignupForm = () => {
  form.value = 'signup'
}

const isLogin = useisLogin()
const { refresh: reUserInfo } = await useHyc('/api/v1/user/data', {
  key: 'userInfo',
  lazy: true,
})
const loginSubmit = async (event: FormSubmitEvent<Schema>) => {
  loading.value = true
  BGPlay.value = false
  try {
    const csrf = (await $hyc('/token/csrf-token')) as {
      data: { token: string }
    }
    const body = {
      ...event.data,
      _token: csrf.data.token,
    }
    const login = (await $hyc('/api/v1/user/login', {
      method: 'POST',
      body: body,
    })) as { data: { token: string } }
    isLogin.value = login.data.token ? true : false
    reUserInfo()
  } catch (e) {
    console.log('err', e)
  }
  BGPlay.value = true
  loading.value = false
}
</script>
<template>
  <UForm :schema="schema" :state="loginf" class="mx-2" @submit="loginSubmit">
    <UFormGroup label="Username" name="username">
      <UInput
        v-model="loginf.username"
        placeholder="Username"
        class="my-4 w-full"
      />
    </UFormGroup>
    <UFormGroup label="Password" name="password">
      <el-input
        v-model="loginf.password"
        class="my-4 w-full"
        type="password"
        placeholder="Please input password"
        show-password
      />
    </UFormGroup>
    <UFormGroup label="2FA" name="2fa">
      <TwoFAInput />
    </UFormGroup>
    <div class="my-4 flex w-full items-center justify-between">
      <div class="flex items-center">
        <UToggle v-model="loginf.remember" /><span class="mx-2"
          >remember me</span
        >
      </div>
      <UButton variant="link">forget</UButton>
    </div>
    <UButton
      block
      :class="[
        loading ? 'animate-bounce cursor-wait' : '',
        errorMessage ? 'bg-red-500 hover:bg-red-500' : '',
        'flex justify-center rounded p-3 text-center text-white transition-all',
      ]"
      :disabled="loading"
      :label="errorMessage || 'Login'"
      type="submit"
    />
    <Webauthn class="my-2" />
    <div class="my-3 flex items-center justify-center">
      <UButton
        color="gray"
        variant="link"
        label="Signup"
        @click="toSignupForm"
      />
      <span class="px-4 text-gray-400">|</span>
      <UButton color="gray" variant="link" label="resend email" />
    </div>
  </UForm>
</template>
